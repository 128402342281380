
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $black;
}


label, .label {
  display: block; margin-bottom: 8px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px 20px;
  margin-bottom: 0;
  display: block;
  background: $white;
  height: 45px;
  line-height: inherit;
  width: 100%;
  border: none;
  box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.05);

  &:focus {
    outline: 0;
    box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.05), inset 5px 0px 0px 0px $accent;
  }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
  width: 100%;
  height: 199px;
  padding: 11px 20px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
button, input[type="reset"], input[type="submit"], .button {
  display: inline-block;
  clear: both;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 12px 55px 12px 25px;
  margin: 10px 0 0;
  background-color: $accent;
  text-decoration: none;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  @include fs(14);
  position: relative;
  letter-spacing: 0.5px;
  @include optimize-font;

  &:after {
    @extend .icon-font;
    content: '\e902';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateX(0) translateY(-50%);
    color: $white;
    font-size: 7px;
    transition: $transitionDefault;
  }

  &:hover, &:focus, &:active {
    color: $white;
    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.1);

    &:after {
      transform: translateX(5px) translateY(-50%);
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

.button-right {
  text-align: right;
}

.button--back {
  padding: 6px 10px 6px 25px;
  background-color: transparent;
  color: $black;
  @include fs(16);
  text-transform: none;
  font-weight: 300;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  top: -40px;
  left: 0;

  &:after {
    left: 0;
    content: '\e901';
    color: $black;
  }

  &:hover, &:focus, &:active {
    color: $black;
    box-shadow: none;

    &:after {
      transform: translateX(-5px) translateY(-50%);
    }
  }

  @media #{$larger} {
    left: 40px;
  }
}

.button--secondary {
  background-color: $grayLight;
  color: $black;
  padding: 11px 20px;
  text-transform: none;
  font-weight: 400;
  box-shadow: inset 0px 0px 0px 1px $black;
  @include fs(16);

  &:after {
    display: none;
  }

  &:hover, &:focus, &:active {
    color: $accent;
    box-shadow: inset 0px 0px 0px 1px $accent;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200,200,200,0.1);
  }
}

::placeholder { color: $black; }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none; background: none; list-style-image: none;
      margin: 0 0 10px; float: left; width: 100%; padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }

  @media #{$medium} {
    button {
      margin-top: 0;
    }
  }

}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left; clear: none; margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }


// Floating labels
.floating-label {
  position: relative;
  vertical-align: top;
  margin-top: 1.4em;

  label {
    @include fs(16);
    position: absolute;
    top: 11px;
    left: 20px;
    transform: translate(0,0);
    white-space: nowrap;
    width: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;

    &.active {
      display: block;
      visibility: visible;
      opacity: 1;
      @include fs(14);
      transform: translate(-20px, -2.5em);
    }
  }

}


// Custom checkbox

.checkbox-wrap {
  position: relative;
  margin-top: 23px;

  &.required {
    &:after {
      position: absolute;
      top: 9px;
      right: 20px;
      height: 7px;
      width: 7px;
      background-color: $gray;
      opacity: 0.2;
      content: '';
      border-radius: 50%;
      pointer-events: none;
    }
  }
}
/* Base for label styling */
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
  position: absolute; left: -9999px;
}

input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
  position: relative;
  padding: 0 0 0 60px;
  cursor: pointer;
  color: $black;
  display: inline-block;
  line-height: 1.35em;
}

/* checkbox aspect */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: $white;
  transition: $transitionDefault;
  box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.05);
}
/* checked mark aspect */
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
  @extend .icon-font;
  content: "\e903";
  position: absolute;
  color: $accent;
  transition: $transitionDefault;
  top: 11px;
  left: 8px;
  font-size: 20px;
}
/* checked mark aspect changes */
input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
input[type="checkbox"]:disabled:not(:checked) + label:before,
input[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  background-color: #ddd;
}
input[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
input[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
input[type="checkbox"]:checked:focus + label:before,
input[type="checkbox"]:not(:checked):focus + label:before {
  border-color: $accent;
  box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.05), inset 5px 0px 0px 0px $accent;
}

#form-news-letter {
  width: 100%;
}

.wrapper-center {
  display: flex;
  justify-content: center;
}
.news-letter-form-wrapper {
  width: 25%;
}

#form-news-letter-footer {
  margin-bottom: 15px;
}
.news-letter-footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button-group {
    text-align: right;
    width: 24%;
  }
  .button {
    margin-top: 0 !important;
    padding: 33px 55px 12px 25px;
  }
}
