/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, body {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: inherit; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block; }

*, *:before, *:after {
  box-sizing: border-box; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none; }

a:focus {
  outline: thin dotted; }

ol, ul {
  list-style: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

figure {
  margin: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

abbr {
  text-transform: uppercase; }

time {
  display: block; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

/*============================================================================
  VARIABLES
==============================================================================*/
@media (min-width: 480px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 768px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1024px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1200px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1400px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1680px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1900px) {
  .init-mediaqueries {
    margin: 0; } }

/*============================================================================
  MIXINS
==============================================================================*/
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    display: table;
    content: " "; }
  .clearfix:after {
    clear: both; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.hidden {
  display: none !important;
  visibility: hidden; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.vh.focusable:active, .vh.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

/*============================================================================
  GRID
==============================================================================*/
.grid, .grid--rev, .grid--full, .grid--g10, .grid--g25 {
  *zoom: 1;
  margin: 0 0 20px -20px; }
  .grid:before, .grid--rev:before, .grid--full:before, .grid--g10:before, .grid--g25:before, .grid:after, .grid--rev:after, .grid--full:after, .grid--g10:after, .grid--g25:after {
    display: table;
    content: " "; }
  .grid:after, .grid--rev:after, .grid--full:after, .grid--g10:after, .grid--g25:after {
    clear: both; }

ul.grid, ul.grid--rev, ul.grid--full, ul.grid--g10, ul.grid--g25,
ul.grid-uniform {
  list-style: none;
  padding: 0; }

.grid__item {
  float: left;
  padding-left: 20px;
  width: 100%; }

.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__item {
    direction: ltr;
    text-align: left;
    float: right; }

.grid--full {
  margin-left: 0; }
  .grid--full > .grid__item {
    padding-left: 0; }

.grid--g10 {
  margin-left: -10px; }
  .grid--g10 > .grid__item {
    padding-left: 10px; }

.grid--g25 {
  margin-left: -25px; }
  .grid--g25 > .grid__item {
    padding-left: 25px; }

.one-whole {
  width: 100%; }

.one-half {
  width: 50%; }

.one-third {
  width: 33.333%; }

.two-thirds {
  width: 66.666%; }

.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

.one-sixth {
  width: 16.666%; }

.two-sixths {
  width: 33.333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.666%; }

.five-sixths {
  width: 83.333%; }

.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

.one-twelfth {
  width: 8.333%; }

.two-twelfths {
  width: 16.666%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.333%; }

.five-twelfths {
  width: 41.666%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.333%; }

.eight-twelfths {
  width: 66.666%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.333%; }

.eleven-twelfths {
  width: 91.666%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

@media only screen and (min-width: 480px) {
  .small--one-whole {
    width: 100%; }
  .small--one-half {
    width: 50%; }
  .small--one-third {
    width: 33.333%; }
  .small--two-thirds {
    width: 66.666%; }
  .small--one-quarter {
    width: 25%; }
  .small--two-quarters {
    width: 50%; }
  .small--three-quarters {
    width: 75%; }
  .small--one-fifth {
    width: 20%; }
  .small--two-fifths {
    width: 40%; }
  .small--three-fifths {
    width: 60%; }
  .small--four-fifths {
    width: 80%; }
  .small--one-sixth {
    width: 16.666%; }
  .small--two-sixths {
    width: 33.333%; }
  .small--three-sixths {
    width: 50%; }
  .small--four-sixths {
    width: 66.666%; }
  .small--five-sixths {
    width: 83.333%; }
  .small--one-eighth {
    width: 12.5%; }
  .small--two-eighths {
    width: 25%; }
  .small--three-eighths {
    width: 37.5%; }
  .small--four-eighths {
    width: 50%; }
  .small--five-eighths {
    width: 62.5%; }
  .small--six-eighths {
    width: 75%; }
  .small--seven-eighths {
    width: 87.5%; }
  .small--one-tenth {
    width: 10%; }
  .small--two-tenths {
    width: 20%; }
  .small--three-tenths {
    width: 30%; }
  .small--four-tenths {
    width: 40%; }
  .small--five-tenths {
    width: 50%; }
  .small--six-tenths {
    width: 60%; }
  .small--seven-tenths {
    width: 70%; }
  .small--eight-tenths {
    width: 80%; }
  .small--nine-tenths {
    width: 90%; }
  .small--one-twelfth {
    width: 8.333%; }
  .small--two-twelfths {
    width: 16.666%; }
  .small--three-twelfths {
    width: 25%; }
  .small--four-twelfths {
    width: 33.333%; }
  .small--five-twelfths {
    width: 41.666%; }
  .small--six-twelfths {
    width: 50%; }
  .small--seven-twelfths {
    width: 58.333%; }
  .small--eight-twelfths {
    width: 66.666%; }
  .small--nine-twelfths {
    width: 75%; }
  .small--ten-twelfths {
    width: 83.333%; }
  .small--eleven-twelfths {
    width: 91.666%; }
  .small--show {
    display: block !important; }
  .small--hide {
    display: none !important; }
  .small--text-left {
    text-align: left !important; }
  .small--text-right {
    text-align: right !important; }
  .small--text-center {
    text-align: center !important; }
  .small--left {
    float: left !important; }
  .small--right {
    float: right !important; } }

@media only screen and (min-width: 768px) {
  .medium--one-whole {
    width: 100%; }
  .medium--one-half {
    width: 50%; }
  .medium--one-third {
    width: 33.333%; }
  .medium--two-thirds {
    width: 66.666%; }
  .medium--one-quarter {
    width: 25%; }
  .medium--two-quarters {
    width: 50%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-fifth {
    width: 20%; }
  .medium--two-fifths {
    width: 40%; }
  .medium--three-fifths {
    width: 60%; }
  .medium--four-fifths {
    width: 80%; }
  .medium--one-sixth {
    width: 16.666%; }
  .medium--two-sixths {
    width: 33.333%; }
  .medium--three-sixths {
    width: 50%; }
  .medium--four-sixths {
    width: 66.666%; }
  .medium--five-sixths {
    width: 83.333%; }
  .medium--one-eighth {
    width: 12.5%; }
  .medium--two-eighths {
    width: 25%; }
  .medium--three-eighths {
    width: 37.5%; }
  .medium--four-eighths {
    width: 50%; }
  .medium--five-eighths {
    width: 62.5%; }
  .medium--six-eighths {
    width: 75%; }
  .medium--seven-eighths {
    width: 87.5%; }
  .medium--one-tenth {
    width: 10%; }
  .medium--two-tenths {
    width: 20%; }
  .medium--three-tenths {
    width: 30%; }
  .medium--four-tenths {
    width: 40%; }
  .medium--five-tenths {
    width: 50%; }
  .medium--six-tenths {
    width: 60%; }
  .medium--seven-tenths {
    width: 70%; }
  .medium--eight-tenths {
    width: 80%; }
  .medium--nine-tenths {
    width: 90%; }
  .medium--one-twelfth {
    width: 8.333%; }
  .medium--two-twelfths {
    width: 16.666%; }
  .medium--three-twelfths {
    width: 25%; }
  .medium--four-twelfths {
    width: 33.333%; }
  .medium--five-twelfths {
    width: 41.666%; }
  .medium--six-twelfths {
    width: 50%; }
  .medium--seven-twelfths {
    width: 58.333%; }
  .medium--eight-twelfths {
    width: 66.666%; }
  .medium--nine-twelfths {
    width: 75%; }
  .medium--ten-twelfths {
    width: 83.333%; }
  .medium--eleven-twelfths {
    width: 91.666%; }
  .medium--show {
    display: block !important; }
  .medium--hide {
    display: none !important; }
  .medium--text-left {
    text-align: left !important; }
  .medium--text-right {
    text-align: right !important; }
  .medium--text-center {
    text-align: center !important; }
  .medium--left {
    float: left !important; }
  .medium--right {
    float: right !important; } }

@media only screen and (min-width: 1024px) {
  .large--one-whole {
    width: 100%; }
  .large--one-half {
    width: 50%; }
  .large--one-third {
    width: 33.333%; }
  .large--two-thirds {
    width: 66.666%; }
  .large--one-quarter {
    width: 25%; }
  .large--two-quarters {
    width: 50%; }
  .large--three-quarters {
    width: 75%; }
  .large--one-fifth {
    width: 20%; }
  .large--two-fifths {
    width: 40%; }
  .large--three-fifths {
    width: 60%; }
  .large--four-fifths {
    width: 80%; }
  .large--one-sixth {
    width: 16.666%; }
  .large--two-sixths {
    width: 33.333%; }
  .large--three-sixths {
    width: 50%; }
  .large--four-sixths {
    width: 66.666%; }
  .large--five-sixths {
    width: 83.333%; }
  .large--one-eighth {
    width: 12.5%; }
  .large--two-eighths {
    width: 25%; }
  .large--three-eighths {
    width: 37.5%; }
  .large--four-eighths {
    width: 50%; }
  .large--five-eighths {
    width: 62.5%; }
  .large--six-eighths {
    width: 75%; }
  .large--seven-eighths {
    width: 87.5%; }
  .large--one-tenth {
    width: 10%; }
  .large--two-tenths {
    width: 20%; }
  .large--three-tenths {
    width: 30%; }
  .large--four-tenths {
    width: 40%; }
  .large--five-tenths {
    width: 50%; }
  .large--six-tenths {
    width: 60%; }
  .large--seven-tenths {
    width: 70%; }
  .large--eight-tenths {
    width: 80%; }
  .large--nine-tenths {
    width: 90%; }
  .large--one-twelfth {
    width: 8.333%; }
  .large--two-twelfths {
    width: 16.666%; }
  .large--three-twelfths {
    width: 25%; }
  .large--four-twelfths {
    width: 33.333%; }
  .large--five-twelfths {
    width: 41.666%; }
  .large--six-twelfths {
    width: 50%; }
  .large--seven-twelfths {
    width: 58.333%; }
  .large--eight-twelfths {
    width: 66.666%; }
  .large--nine-twelfths {
    width: 75%; }
  .large--ten-twelfths {
    width: 83.333%; }
  .large--eleven-twelfths {
    width: 91.666%; }
  .large--show {
    display: block !important; }
  .large--hide {
    display: none !important; }
  .large--text-left {
    text-align: left !important; }
  .large--text-right {
    text-align: right !important; }
  .large--text-center {
    text-align: center !important; }
  .large--left {
    float: left !important; }
  .large--right {
    float: right !important; } }

/*============================================================================
  TYPOGRAPHY
==============================================================================*/
p {
  line-height: 1.8em;
  margin-bottom: 1.5em; }

strong {
  font-weight: 600;
  font-size: inherit; }

em {
  font-style: italic;
  font-weight: inherit; }

a {
  font-weight: inherit;
  font-style: inherit;
  color: #4F3A12;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  text-decoration: none;
  text-decoration-skip: ink; }
  a:hover, a:focus {
    color: #F8AE15;
    text-decoration: none; }
  a:focus, a:active {
    outline: 0;
    text-decoration: none; }
  a.tdu, .rte a:not(.button) {
    text-decoration: underline; }
    a.tdu:hover, .rte a:hover:not(.button), a.tdu:focus, .rte a:focus:not(.button) {
      text-decoration: underline; }

a:not([href]) {
  color: #4F3A12;
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle; }

h1, .h1, h2, .h2, h3, .h3, .article-related__title, .card__title, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Poppins', Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #4F3A12;
  line-height: 1.2em;
  margin: 0 0 1.2em; }
  h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, .article-related__title a, .card__title a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: #F8AE15; }
    h1 a:hover, h1 a:focus, .h1 a:hover, .h1 a:focus, h2 a:hover, h2 a:focus, .h2 a:hover, .h2 a:focus, h3 a:hover, h3 a:focus, .h3 a:hover, .article-related__title a:hover, .card__title a:hover, .h3 a:focus, .article-related__title a:focus, .card__title a:focus, h4 a:hover, h4 a:focus, .h4 a:hover, .h4 a:focus, h5 a:hover, h5 a:focus, .h5 a:hover, .h5 a:focus, h6 a:hover, h6 a:focus, .h6 a:hover, .h6 a:focus {
      color: #4F3A12; }

h1, .h1 {
  font-size: 24px; }
  @media screen and (min-width: 768px) {
    h1, .h1 {
      font-size: calc(24px + 8 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h1, .h1 {
      font-size: 32px; } }

h2, .h2 {
  font-size: 24px; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: calc(24px + 8 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h2, .h2 {
      font-size: 32px; } }

h3, .h3, .article-related__title, .card__title {
  font-size: 20px; }
  @media screen and (min-width: 768px) {
    h3, .h3, .article-related__title, .card__title {
      font-size: calc(20px + 4 * (100vw - 768px) / 256); } }
  @media screen and (min-width: 1024px) {
    h3, .h3, .article-related__title, .card__title {
      font-size: 24px; } }

h4, .h4 {
  font-size: 16px; }
  @media screen and (min-width: 768px) {
    h4, .h4 {
      font-size: calc(16px + 4 * (100vw - 768px) / 256); } }
  @media screen and (min-width: 1024px) {
    h4, .h4 {
      font-size: 20px; } }

ul, ol {
  margin: 0 0 1.5em 16px; }
  ul li, ol li {
    line-height: 1.5em;
    padding: 0; }

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0; }

ul {
  list-style: none;
  margin: 0; }
  ul ul {
    list-style: none; }
  ul.lined li {
    border-bottom: 1px solid #E3E3E3;
    padding: 5px 0; }
    ul.lined li:first-child {
      padding-top: 0; }
    ul.lined li:last-child {
      padding-bottom: 0;
      border-bottom: none; }

ol {
  list-style: decimal; }

.unstyled, ul.lined, .page-footer__list, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none; }
  .unstyled ul, ul.lined ul, .page-footer__list ul, nav ul ul {
    list-style: none; }
  .unstyled li, ul.lined li, .page-footer__list li, nav ul li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none; }

.styled li,
.rte ul li {
  padding-left: 35px;
  position: relative; }
  .styled li:before,
  .rte ul li:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 7px;
    height: 7px;
    background-color: #F8AE15; }

nav ul {
  *zoom: 1; }
  nav ul:before, nav ul:after {
    display: table;
    content: " "; }
  nav ul:after {
    clear: both; }

dl {
  margin: 0 0 1.5em; }

dt, dd {
  line-height: 1.5em; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: 0.5em; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #B4B4B4;
  margin: 50px 0; }

blockquote {
  line-height: inherit;
  border-left: 1px solid #4F3A12;
  font-weight: 700;
  position: relative;
  margin: 40px 0 40px 55px;
  padding-left: 25px;
  padding-right: 35px;
  max-width: 540px;
  font-size: 16px; }
  @media screen and (min-width: 768px) {
    blockquote {
      font-size: calc(16px + 4 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    blockquote {
      font-size: 20px; } }
  blockquote:before {
    color: #F8AE15;
    font-size: 28px;
    font-size: 2.8rem;
    position: absolute;
    top: 0;
    left: -55px; }
  blockquote p {
    margin-bottom: 0; }
  blockquote cite {
    font-size: 14px;
    font-size: 1.4rem; }
  @media (min-width: 768px) {
    blockquote {
      margin: 60px 0 60px 70px; }
      blockquote:before {
        left: -70px;
        font-size: 38px;
        font-size: 3.8rem; } }

/*============================================================================
  TABLES
==============================================================================*/
table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 1.5em; }
  table th, table td {
    padding: 11px 6px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
    line-height: 1.5em; }
  table th {
    font-weight: bold;
    border-bottom: 2px solid #ddd; }

/*============================================================================
  FORMS
==============================================================================*/
form {
  margin: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px; }

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

label, .label, input, select, textarea {
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  vertical-align: middle;
  color: #4F3A12; }

label, .label {
  display: block;
  margin-bottom: 8px; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none; }

input[type="checkbox"], input[type="radio"] {
  cursor: pointer; }

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px 20px;
  margin-bottom: 0;
  display: block;
  background: #fff;
  height: 45px;
  line-height: inherit;
  width: 100%;
  border: none;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.05); }
  input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus,
  input[type="email"]:focus, input[type="tel"]:focus, input[type="date"]:focus, textarea:focus, select:focus {
    outline: 0;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.05), inset 5px 0px 0px 0px #F8AE15; }

input[type="email"] {
  display: block; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
  width: 100%;
  height: 199px;
  padding: 11px 20px; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none; }

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

button, input[type="reset"], input[type="submit"], .button {
  display: inline-block;
  clear: both;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  outline: 0;
  line-height: 1.2em;
  padding: 12px 55px 12px 25px;
  margin: 10px 0 0;
  background-color: #F8AE15;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  position: relative;
  letter-spacing: 0.5px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }
  button:after, input[type="reset"]:after, input[type="submit"]:after, .button:after {
    content: '\e902';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateX(0) translateY(-50%);
    color: #fff;
    font-size: 7px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  button:hover, button:focus, button:active, input[type="reset"]:hover, input[type="reset"]:focus, input[type="reset"]:active, input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, .button:hover, .card:hover .button, .card:focus .button, .button:focus, .button:active {
    color: #fff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1); }
    button:hover:after, button:focus:after, button:active:after, input[type="reset"]:hover:after, input[type="reset"]:focus:after, input[type="reset"]:active:after, input[type="submit"]:hover:after, input[type="submit"]:focus:after, input[type="submit"]:active:after, .button:hover:after, .card:hover .button:after, .card:focus .button:after, .button:focus:after, .button:active:after {
      transform: translateX(5px) translateY(-50%); }
  button:active, input[type="reset"]:active, input[type="submit"]:active, .button:active {
    transform: translateY(1px); }
  button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, .button::-moz-focus-inner {
    border: 0; }

.button-right {
  text-align: right; }

.button--back {
  padding: 6px 10px 6px 25px;
  background-color: transparent;
  color: #4F3A12;
  font-size: 16px;
  font-size: 1.6rem;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  top: -40px;
  left: 0; }
  .button--back:after {
    left: 0;
    content: '\e901';
    color: #4F3A12; }
  .button--back:hover, .button--back:focus, .button--back:active {
    color: #4F3A12;
    box-shadow: none; }
    .button--back:hover:after, .button--back:focus:after, .button--back:active:after {
      transform: translateX(-5px) translateY(-50%); }
  @media (min-width: 1200px) {
    .button--back {
      left: 40px; } }

.button--secondary, .filter__button {
  background-color: #FBFBFB;
  color: #4F3A12;
  padding: 11px 20px;
  text-transform: none;
  font-weight: 400;
  box-shadow: inset 0px 0px 0px 1px #4F3A12;
  font-size: 16px;
  font-size: 1.6rem; }
  .button--secondary:after, .filter__button:after {
    display: none; }
  .button--secondary:hover, .filter__button:hover, .button--secondary:focus, .filter__button:focus, .button--secondary:active, .filter__button:active {
    color: #F8AE15;
    box-shadow: inset 0px 0px 0px 1px #F8AE15; }

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none; }

button:focus, input[type="reset"]:focus, input[type="submit"]:focus, .button:focus, a.button:focus, select:focus {
  outline: 0;
  *outline: inherit;
  box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1); }

::placeholder {
  color: #4F3A12; }

::-ms-clear {
  display: none; }

input[type="search"] {
  -webkit-appearance: none; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-webkit-search-results-button {
  -webkit-appearance: none; }

::-webkit-inner-spin-button {
  -webkit-appearance: none; }

::-webkit-outer-spin-button {
  -webkit-appearance: none; }

form .row {
  margin-bottom: 0; }

fieldset {
  width: auto; }
  fieldset p, fieldset ul, fieldset li {
    *zoom: 1; }
    fieldset p:before, fieldset p:after, fieldset ul:before, fieldset ul:after, fieldset li:before, fieldset li:after {
      display: table;
      content: " "; }
    fieldset p:after, fieldset ul:after, fieldset li:after {
      clear: both; }
  fieldset ul {
    margin-left: -20px; }
    fieldset ul li {
      list-style: none;
      background: none;
      list-style-image: none;
      margin: 0 0 10px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px; }
      fieldset ul li.error input {
        border-color: #f1062c; }
      fieldset ul li.space {
        margin-top: 25px; }
      @media (min-width: 768px) {
        fieldset ul li.one-half {
          width: 50%; }
        fieldset ul li.one-fourth {
          width: 25%; } }
  @media (min-width: 768px) {
    fieldset button {
      margin-top: 0; } }

.buttons {
  *zoom: 1; }
  .buttons:before, .buttons:after {
    display: table;
    content: " "; }
  .buttons:after {
    clear: both; }
  .buttons button, .buttons input[type="reset"], .buttons input[type="submit"], .buttons .button, .buttons a.button {
    float: left;
    clear: none;
    margin-right: 15px; }
    .buttons button.right, .buttons input[type="reset"].right, .buttons input[type="submit"].right, .buttons .button.right, .buttons a.button.right {
      float: right;
      margin-right: 0;
      margin-left: 15px; }

.a-c .button, .a-c a.button, .a-c button {
  float: none;
  clear: none;
  display: inline-block; }

.grecaptcha-badge {
  display: none; }

.floating-label {
  position: relative;
  vertical-align: top;
  margin-top: 1.4em; }
  .floating-label label {
    font-size: 16px;
    font-size: 1.6rem;
    position: absolute;
    top: 11px;
    left: 20px;
    transform: translate(0, 0);
    white-space: nowrap;
    width: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s; }
    .floating-label label.active {
      display: block;
      visibility: visible;
      opacity: 1;
      font-size: 14px;
      font-size: 1.4rem;
      transform: translate(-20px, -2.5em); }

.checkbox-wrap {
  position: relative;
  margin-top: 23px; }
  .checkbox-wrap.required:after {
    position: absolute;
    top: 9px;
    right: 20px;
    height: 7px;
    width: 7px;
    background-color: #B4B4B4;
    opacity: 0.2;
    content: '';
    border-radius: 50%;
    pointer-events: none; }

/* Base for label styling */
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
  position: relative;
  padding: 0 0 0 60px;
  cursor: pointer;
  color: #4F3A12;
  display: inline-block;
  line-height: 1.35em; }

/* checkbox aspect */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: #fff;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.05); }

/* checked mark aspect */
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
  content: "\e903";
  position: absolute;
  color: #F8AE15;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  top: 11px;
  left: 8px;
  font-size: 20px; }

/* checked mark aspect changes */
input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* disabled checkbox */
input[type="checkbox"]:disabled:not(:checked) + label:before,
input[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  background-color: #ddd; }

input[type="checkbox"]:disabled:checked + label:after {
  color: #999; }

input[type="checkbox"]:disabled + label {
  color: #aaa; }

/* accessibility */
input[type="checkbox"]:checked:focus + label:before,
input[type="checkbox"]:not(:checked):focus + label:before {
  border-color: #F8AE15;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.05), inset 5px 0px 0px 0px #F8AE15; }

#form-news-letter {
  width: 100%; }

.wrapper-center {
  display: flex;
  justify-content: center; }

.news-letter-form-wrapper {
  width: 25%; }

#form-news-letter-footer {
  margin-bottom: 15px; }

.news-letter-footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .news-letter-footer__wrapper .button-group {
    text-align: right;
    width: 24%; }
  .news-letter-footer__wrapper .button {
    margin-top: 0 !important;
    padding: 33px 55px 12px 25px; }

.formError {
  z-index: 990; }

.formError .formErrorContent {
  z-index: 991; }

.formError .formErrorArrow {
  z-index: 996; }

.formErrorInsideDialog.formError {
  z-index: 5000; }

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001; }

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006; }

.inputContainer {
  position: relative;
  float: left; }

.formError, .formError * {
  box-sizing: content-box; }

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  margin: 0 0 0 -105px; }

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none; }

.formError .formErrorContent {
  background: #f1062c;
  position: relative;
  color: #fff;
  width: 150px;
  border: 1px solid #f1062c;
  padding: 4px 10px 4px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

.formError .formErrorArrow {
  /*width: 15px; margin: -2px 0 0 13px;*/
  position: relative;
  margin: -2px 0 0 145px;
  height: 10px; }
  .formError .formErrorArrow:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 8px solid transparent;
    border-bottom: 0;
    border-top-color: #f1062c; }

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px; }

.formError .formErrorArrow div {
  display: none; }

/*!
	Modaal - accessible modals - v0.4.2
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden; }

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0; }

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out; }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden; }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none; }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1; }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0; }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important; }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden; }

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block; }

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px; }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top; }

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto; }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden; }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto; }

.modaal-close {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 0;
  color: #4F3A12;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  transition: all 0.2s ease-in-out;
  text-align: center; }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    box-shadow: none; }
    .modaal-close:focus:before, .modaal-close:hover:before {
      color: #F8AE15; }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-close:before {
    width: 50%;
    color: #4F3A12;
    font-size: 24px;
    font-size: 2.4rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 50%;
    left: 50%;
    position: absolute;
    display: block;
    transition: background 0.2s ease-in-out; }
  .modaal-close:after {
    display: none; }

.modaal-content-container {
  overflow: hidden; }

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0; }

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent; }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out; }
    .modaal-confirm-btn.modaal-ok:hover {
      background: #2f2f2f; }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline; }
    .modaal-confirm-btn.modaal-cancel:hover {
      text-decoration: none;
      color: #2f2f2f; }

@keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important; }

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent; }

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important; }

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards; }

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px; }

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%; }

.modaal-gallery-wrap {
  position: relative;
  color: #fff; }

.modaal-gallery-item {
  display: none; }
  .modaal-gallery-item img {
    display: block; }
  .modaal-gallery-item.is_active {
    display: block; }

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff; }
  .modaal-gallery-label:focus {
    outline: none; }

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default; }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff; }
    .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
      background: #afb7bc; }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg); }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg); }

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px; }

.modaal-gallery-next-outer {
  right: 45px; }

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px; }

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg); }

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg); }

.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px; }

.modaal-gallery-prev-outer {
  left: 45px; }

.modaal-video-wrap {
  margin: auto 50px;
  position: relative; }

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%; }

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block; }

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px; } }

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px; }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7); }
    .modaal-gallery-control:before, .modaal-gallery-control:after {
      background: #fff; }
  .modaal-gallery-next {
    left: auto;
    right: 20px; }
  .modaal-gallery-prev {
    left: 20px;
    right: auto; } }

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; } }

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important; } }

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px; }
  .modaal-instagram iframe {
    width: 600px !important; } }

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px; } }

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important; } }

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none; } }

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25); }

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute; }

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff; }

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s; }

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s; }

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s; }

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s; }

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s; }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s; }

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s; }

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s; }

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container:focus {
    outline: 0 !important; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 41px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single:focus {
      outline: 0 !important; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 20px;
      padding-right: 45px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }

.select2-dropdown {
  background-color: #FBFBFB;
  border: 1px solid #4F3A12;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: auto;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 12px 20px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 1; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #FBFBFB;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #FBFBFB;
  border: 1px solid #4F3A12; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #4F3A12;
    line-height: 41px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #4F3A12; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 39px;
    width: 45px;
    position: absolute;
    top: 1px;
    right: 1px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      font-size: 6px;
      transition: 0.15s linear;
      backface-visibility: hidden; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      display: none; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #FBFBFB;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:before {
  transform: translate(-50%, -50%) rotate(180deg); }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #4F3A12; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #F8AE15;
  color: #4F3A12; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s, visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s, visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0 6px; }

.fancybox-slide--iframe {
  padding: 44px 44px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px 0;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  vertical-align: top; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:focus,
.fancybox-button:hover {
  color: #fff; }

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default; }

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision; }

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3; }

.fancybox-button--play svg path:nth-child(2) {
  display: none; }

.fancybox-button--pause svg path:nth-child(1) {
  display: none; }

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
  fill: currentColor; }

.fancybox-button--share svg path {
  stroke-width: 1; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s, visibility 0s, color .25s; }

.fancybox-navigation .fancybox-button::after {
  content: '';
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10; }

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s; }

.fancybox-close-small:focus {
  outline: none; }

.fancybox-close-small:hover svg {
  opacity: 1; }

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg,
.fancybox-slide--iframe .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: '';
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(100, 100, 100, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x > ul {
  overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }

/* Custom */
.fancybox-close-small:after {
  display: none; }

/* =============================================================================
   LAYOUT
   ========================================================================== */
html {
  font-size: 62.5%; }

body {
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #4F3A12;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

.page-wrap {
  position: relative; }
  @media (min-width: 768px) {
    .page-wrap {
      min-height: 100vh;
      z-index: 2; } }

.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }

.container--large {
  max-width: 1670px; }
  .container--large .container {
    padding: 0; }
    @media (min-width: 1200px) {
      .container--large .container {
        padding-left: 40px;
        padding-right: 40px; } }

.container--full {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0; }

.container--gray {
  background-color: #FBFBFB;
  padding-top: 45px;
  padding-bottom: 45px; }
  .container--gray p:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .container--gray {
      padding-top: 75px;
      padding-bottom: 75px; } }

.container--padding {
  padding-bottom: 305px;
  margin-bottom: -260px; }
  @media (min-width: 768px) {
    .container--padding {
      margin-bottom: -230px; } }

.container--padding-2 {
  padding-bottom: 245px;
  margin-bottom: -200px; }
  @media (min-width: 768px) {
    .container--padding-2 {
      margin-bottom: -170px; } }

.container--margin {
  margin-bottom: 45px; }
  @media (min-width: 768px) {
    .container--margin {
      margin-bottom: 75px; } }

.container--hero {
  min-height: 425px; }

.container---with-slideshow {
  padding-bottom: 145px;
  margin-bottom: -100px; }
  @media (min-width: 768px) {
    .container---with-slideshow {
      padding-bottom: 175px; } }

.wf-loading {
  overflow: hidden; }
  .wf-loading body {
    visibility: hidden; }

::selection {
  background-color: #fef3db;
  color: inherit; }

/* =============================================================================
   HELPERS
   ========================================================================== */
.icon {
  display: inline-block;
  vertical-align: middle; }

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid #F8AE15;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.icon-hamburger {
  position: relative; }
  .icon-hamburger:before, .icon-hamburger:after {
    content: "";
    margin-left: 0; }
  .icon-hamburger:before {
    margin-top: 3px; }
  .icon-hamburger:after {
    margin-top: 9px; }

@font-face {
  font-family: "toontjeshuis";
  src: url("../fonts/toontjeshuis.eot?kotmn0");
  src: url("../fonts/toontjeshuis.eot?kotmn0#iefix") format("embedded-opentype"), url("../fonts/toontjeshuis.ttf?kotmn0") format("truetype"), url("../fonts/toontjeshuis.woff?kotmn0") format("woff"), url("../fonts/toontjeshuis.svg?kotmn0#toontjeshuis") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"],
.icon-font,
blockquote:before,
button:after,
input[type="reset"]:after,
input[type="submit"]:after,
.button:after,
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after,
.modaal-close:before,
.select2-container--default .select2-selection--single .select2-selection__arrow:before,
.grid__video,
.accordion-trigger:before,
.accordion-trigger:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "toontjeshuis" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add:before {
  content: "\e900"; }

.icon-arrow-left:before {
  content: "\e901"; }

.icon-arrow-right:before {
  content: "\e902"; }

.icon-checkmark:before {
  content: "\e903"; }

.icon-chevron-down:before, .select2-container--default .select2-selection--single .select2-selection__arrow:before {
  content: "\e904"; }

.icon-chevron-left:before {
  content: "\e905"; }

.icon-chevron-right:before {
  content: "\e906"; }

.icon-facebook:before {
  content: "\e907"; }

.icon-linkedin:before {
  content: "\e908"; }

.icon-minus:before {
  content: "\e909"; }

.icon-play:before, .grid__video:before {
  content: "\e90a"; }

.icon-plus:before, .modaal-close:before {
  content: "\e90b"; }

.icon-quote:before, blockquote:before {
  content: "\e90c"; }

.icon-twitter:before {
  content: "\e90d"; }

.mb0.mb0 {
  margin-bottom: 0; }

.gm-style img {
  max-width: inherit; }

.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap; }
  .equal-h > * {
    display: flex; }
  .equal-h:before, .equal-h:after {
    display: none; }

html.no-flexbox .equal-h {
  height: 100%; }
  html.no-flexbox .equal-h > div {
    float: left; }

.block-link, .card:not(.card--no-link) {
  position: relative;
  cursor: pointer; }

.block-link__target:before, .card__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  *zoom: 1;
  padding: 20px 0;
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .page-header:before, .page-header:after {
    display: table;
    content: " "; }
  .page-header:after {
    clear: both; }
  .page-header.small {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05); }
  .page-header .container {
    position: static; }
  @media (min-width: 768px) {
    .page-header {
      height: 110px;
      padding: 35px 0; }
      .page-header.small {
        padding: 20px 0;
        height: 80px; }
        .page-header.small .page-header__logo {
          margin-top: 0; }
          .page-header.small .page-header__logo img {
            width: 175px; }
        .page-header.small .main-nav {
          margin: 12px 40px 0; }
          .page-header.small .main-nav .main-nav__aside {
            top: 0;
            transform: rotate(-90deg) translateY(-40px); }
            .page-header.small .main-nav .main-nav__aside a {
              width: 80px;
              font-size: 12px;
              font-size: 1.2rem;
              padding: 12px 0 15px;
              height: 40px; } }
  @media (min-width: 1024px) {
    .page-header {
      height: 175px;
      padding: 40px 0; } }
  @media (min-width: 1400px) {
    .page-header.small .main-nav {
      margin: 12px 0 0; } }

.page-header__logo {
  text-decoration: none;
  opacity: 1;
  backface-visibility: hidden;
  float: left; }
  .page-header__logo img {
    display: block;
    width: 175px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .page-header__logo:hover, .page-header__logo:focus {
    opacity: 0.8;
    backface-visibility: hidden; }
  @media (min-width: 1024px) {
    .page-header__logo {
      margin-top: 22px; }
      .page-header__logo img {
        width: 250px; } }
  @media (min-width: 1200px) {
    .page-header__logo {
      margin-top: 0; }
      .page-header__logo img {
        width: 385px; } }

.main-nav {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #4F3A12;
  float: right;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .main-nav a {
    color: #4F3A12;
    padding: 12px 15px;
    display: block;
    font-weight: 600;
    font-size: 17px;
    font-size: 1.7rem;
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      .main-nav a {
        font-size: calc(14px + 3 * (100vw - 768px) / 432); } }
    @media screen and (min-width: 1200px) {
      .main-nav a {
        font-size: 17px; } }
  .main-nav a:hover,
  .main-nav a:focus,
  .main-nav li.active > a {
    color: #F8AE15; }
  .main-nav li.main-nav__aside.active a {
    color: #fff;
    background: #F8AE15; }
  @media (min-width: 768px) {
    .main-nav {
      margin: 17px 40px 0; }
      .main-nav li {
        float: left;
        margin: 0 0 0 15px;
        position: relative; }
      .main-nav a {
        border-top: 0;
        padding: 0; }
      .main-nav li:hover a + .sub-nav,
      .main-nav li:focus a + .sub-nav,
      .main-nav a:hover + .sub-nav,
      .main-nav a:focus + .sub-nav {
        visibility: visible;
        opacity: 1;
        transform: translateX(0) translateY(0); }
      .main-nav .main-nav__aside {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(-90deg) translateY(-40px);
        margin: 0;
        transform-origin: top right;
        transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
        .main-nav .main-nav__aside a {
          background-color: #4F3A12;
          color: #fff;
          width: 110px;
          text-align: center;
          padding: 12px 0 15px;
          height: 40px;
          text-transform: uppercase;
          font-size: 12px;
          font-size: 1.2rem;
          font-weight: 700;
          transform: translateZ(0); }
          .main-nav .main-nav__aside a:hover, .main-nav .main-nav__aside a:focus {
            background-color: #F8AE15;
            color: #fff; } }
  @media (min-width: 1024px) {
    .main-nav {
      margin: 48px 40px 0; }
      .main-nav li {
        margin: 0 0 0 25px; }
      .main-nav a {
        font-weight: 600; }
      .main-nav .main-nav__aside {
        top: 35px;
        transform: rotate(-90deg) translateY(-50px); }
        .main-nav .main-nav__aside a {
          padding: 13px 0 23px;
          height: 50px;
          font-size: 14px;
          font-size: 1.4rem; } }
  @media (min-width: 1400px) {
    .main-nav {
      margin: 48px 0 0; } }

.main-nav__trigger {
  display: block;
  position: relative;
  padding: 12px 15px 12px 30px;
  background-color: transparent;
  color: #4F3A12;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px -15px 0 0;
  font-weight: 600;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1;
  text-transform: none; }
  .main-nav__trigger:after {
    display: none; }
  .main-nav__trigger .icon-hamburger {
    position: absolute;
    right: 0;
    top: 12px;
    display: block;
    margin: 0; }
  .main-nav__trigger:hover, .main-nav__trigger:focus {
    background-color: transparent;
    box-shadow: none;
    color: #F8AE15; }
  .main-nav__trigger:active {
    transform: none; }
  @media (min-width: 768px) {
    .main-nav__trigger {
      display: none; } }

.main-nav__wrap {
  display: none;
  margin: 0 0 15px; }
  @media (min-width: 768px) {
    .main-nav__wrap {
      margin: 0 0 0 -20px;
      display: block; } }

.sub-nav {
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 12px;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  min-width: 100%; }

.sub-nav__wrap {
  margin: 0 -25px;
  background-color: #fff;
  padding-bottom: 10px; }
  .sub-nav__wrap li {
    margin: 0;
    display: block;
    float: none; }
    .sub-nav__wrap li a {
      font-size: 14px;
      font-size: 1.4rem;
      padding: 8px 25px; }

.modaal-container {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.modaal-content-container li {
  list-style: none;
  text-align: center; }

.modaal-content-container a {
  color: #4F3A12;
  padding: 12px 15px;
  display: block;
  font-weight: 600;
  font-size: 20px;
  font-size: 2rem; }

.modaal-content-container a:hover,
.modaal-content-container a:focus,
.modaal-content-container li.active a {
  color: #F8AE15; }

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap; }
  .skip-link:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    font-size: 20px;
    font-size: 2rem; }

/* =============================================================================
   MAIN
   ========================================================================== */
.main {
  padding: 80px 0 20px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .main {
      padding: 110px 0 480px; } }
  @media (min-width: 1024px) {
    .main {
      padding: 175px 0 480px; } }

/* =============================================================================
   FOOTER
   ========================================================================== */
.page-footer {
  position: relative;
  padding: 30px 0;
  background-color: #fff; }
  .page-footer .grid, .page-footer .grid--rev, .page-footer .grid--full, .page-footer .grid--g10, .page-footer .grid--g25 {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .page-footer {
      height: 480px;
      margin: -480px 0 0;
      z-index: 5;
      padding: 135px 0; } }

.page-footer__list {
  *zoom: 1;
  text-align: center; }
  .page-footer__list:before, .page-footer__list:after {
    display: table;
    content: " "; }
  .page-footer__list:after {
    clear: both; }
  .page-footer__list li {
    margin-bottom: 5px; }
    .page-footer__list li p {
      margin-bottom: 5px;
      line-height: 2em; }
      .page-footer__list li p:last-child {
        margin-bottom: 0; }
  @media (min-width: 768px) {
    .page-footer__list li {
      padding-right: 15px;
      margin-bottom: 0; }
      .page-footer__list li p {
        margin-bottom: 0; } }
  @media (min-width: 1024px) {
    .page-footer__list li {
      padding-right: 20px;
      float: left; } }
  @media (min-width: 1400px) {
    .page-footer__list li {
      padding-right: 30px; } }

@media (min-width: 768px) {
  .page-footer__list--left {
    text-align: left; } }

@media (min-width: 768px) {
  .page-footer__list--right {
    float: right;
    text-align: left; }
    .page-footer__list--right li {
      width: 50%;
      float: left; } }

@media (min-width: 1024px) {
  .page-footer__list--right li {
    width: auto; } }

.page-footer__list--center li {
  float: none;
  padding-right: 0; }

.page-footer__bottom {
  position: relative;
  height: 20px;
  margin-top: 30px; }

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(-50%, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 0; }
  a.esign:hover, a.esign:focus {
    opacity: 1; }
  @media (min-width: 768px) {
    a.esign {
      width: 16px; }
      a.esign:hover, a.esign:focus {
        opacity: 1;
        width: 80px; } }

/* =============================================================================
   COMPONENTS
   ========================================================================== */
.large {
  font-size: 20px;
  font-size: 2rem; }

.center-block {
  *zoom: 1;
  text-align: center;
  margin: 20px 0; }
  .center-block:before, .center-block:after {
    display: table;
    content: " "; }
  .center-block:after {
    clear: both; }
  .center-block .button {
    float: none;
    display: inline-block; }

section {
  position: relative; }

article {
  *zoom: 1;
  margin: 0 0 20px; }
  article:before, article:after {
    display: table;
    content: " "; }
  article:after {
    clear: both; }
  article a img {
    display: block;
    opacity: 1;
    backface-visibility: hidden; }
  article a:hover img, article a:focus img {
    opacity: 0.8;
    backface-visibility: hidden; }

.article-detail {
  margin-top: 80px; }

.article-detail__header {
  margin-bottom: 30px;
  padding-top: 20px; }
  @media (min-width: 768px) {
    .article-detail__header h1 {
      margin-bottom: 0; }
    .article-detail__header .grid__item.medium--one-sixth {
      display: flex;
      align-items: center;
      justify-content: flex-end; } }

.article-detail__intro {
  font-weight: 600; }

.article-detail__image {
  margin-bottom: 55px;
  text-align: center; }

.article-related__title {
  margin-bottom: 50px; }

.page-section {
  margin-bottom: 70px; }
  .page-section:focus {
    outline: 0; }

.note {
  *zoom: 1;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef; }
  .note:before, .note:after {
    display: table;
    content: " "; }
  .note:after {
    clear: both; }
  .note ul,
  .note p {
    margin-bottom: 1em !important; }

.note--success {
  color: #39963a;
  background-color: #eff9ef; }

.note--error {
  color: #f1062c;
  background-color: #fff7f8; }

.slideshow {
  position: relative;
  padding-bottom: 60px; }
  @media (min-width: 768px) {
    .slideshow {
      padding-bottom: 150px; } }

.slideshow--rev {
  padding-bottom: 75px; }
  .slideshow--rev .grid .grid__item:first-child, .slideshow--rev .grid--rev .grid__item:first-child, .slideshow--rev .grid--full .grid__item:first-child, .slideshow--rev .grid--g10 .grid__item:first-child, .slideshow--rev .grid--g25 .grid__item:first-child {
    float: right; }
  @media (min-width: 768px) {
    .slideshow--rev .slideshow__body {
      margin-left: 0;
      margin-right: -35px; } }
  @media (min-width: 1024px) {
    .slideshow--rev .slideshow__image {
      margin-right: 0;
      margin-left: -80px; } }

.slideshow__container {
  z-index: 1;
  line-height: 0; }
  .slideshow__container .slick-list {
    overflow: visible; }

.slideshow__current {
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .slideshow__current {
      left: 30px;
      text-align: right; } }
  @media (min-width: 1024px) {
    .slideshow__current {
      left: 120px; } }
  @media (min-width: 1680px) {
    .slideshow__current {
      left: 190px; } }

.slideshow__navigation {
  z-index: 2;
  position: absolute;
  top: 240px;
  left: 0;
  right: 0; }
  @media (min-width: 768px) {
    .slideshow__navigation {
      top: 0;
      left: auto;
      right: 0;
      width: 40%; } }

.slideshow__prev,
.slideshow__next {
  background-color: transparent;
  color: #4F3A12;
  border: 1px solid #4F3A12;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-left: 5px;
  position: absolute;
  top: 0; }
  .slideshow__prev:after,
  .slideshow__next:after {
    color: #4F3A12;
    font-size: 10px;
    font-size: 1rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    line-height: 38px;
    height: 38px;
    display: block;
    transform: translate(0, 0); }
  .slideshow__prev:hover, .slideshow__prev:focus,
  .slideshow__next:hover,
  .slideshow__next:focus {
    background-color: transparent;
    color: #F8AE15;
    border: 1px solid #F8AE15; }
    .slideshow__prev:hover:after, .slideshow__prev:focus:after,
    .slideshow__next:hover:after,
    .slideshow__next:focus:after {
      color: #F8AE15; }

.slideshow__prev {
  right: 80px; }
  .slideshow__prev:after {
    content: "\e905"; }
  .slideshow__prev:hover:after, .slideshow__prev:focus:after {
    transform: translate(-2px, 0); }
  @media (min-width: 768px) {
    .slideshow__prev {
      right: 80px; } }
  @media (min-width: 1400px) {
    .slideshow__prev {
      right: 160px; } }
  @media (min-width: 1900px) {
    .slideshow__prev {
      right: 290px; } }

.slideshow__next {
  right: 30px; }
  .slideshow__next:after {
    content: "\e906"; }
  .slideshow__next:hover:after, .slideshow__next:focus:after {
    transform: translate(2px, 0) scale(1); }
  @media (min-width: 768px) {
    .slideshow__next {
      right: 30px; } }
  @media (min-width: 1400px) {
    .slideshow__next {
      right: 110px; } }
  @media (min-width: 1900px) {
    .slideshow__next {
      right: 240px; } }

.slideshow__image {
  height: 100%;
  min-height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .slideshow__image img {
    display: none; }
  @media (min-width: 768px) {
    .slideshow__image {
      min-height: 550px; } }
  @media (min-width: 1024px) {
    .slideshow__image {
      margin-right: -80px; } }

.slideshow__body {
  border-top: 7px solid #F8AE15;
  padding: 30px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px; }
  .slideshow__body .button {
    margin-top: 0;
    align-self: flex-start; }
  @media (min-width: 768px) {
    .slideshow__body {
      margin-top: 100px;
      margin-left: -35px;
      min-height: 450px; } }
  @media (min-width: 1200px) {
    .slideshow__body {
      padding: 40px 60px; } }
  @media (min-width: 1400px) {
    .slideshow__body {
      padding: 60px 100px; } }
  @media (min-width: 1680px) {
    .slideshow__body {
      padding: 85px 150px; } }

.slideshow__slide .grid, .slideshow__slide .grid--rev, .slideshow__slide .grid--full, .slideshow__slide .grid--g10, .slideshow__slide .grid--g25 {
  margin-bottom: 0; }

.map {
  width: 100%;
  height: 350px;
  position: relative;
  margin: 0; }
  @media (min-width: 768px) {
    .map {
      height: calc(100vh - 218px); } }
  @media (min-width: 1024px) {
    .map {
      height: calc(100vh - 283px);
      max-height: calc(100vh - 283px); } }
  .map button:after {
    content: none; }

.map__marker {
  position: absolute;
  width: 0;
  height: 0; }

.map__marker-inner {
  position: absolute;
  background-color: #fff;
  width: 150px;
  bottom: 16px;
  left: -150px;
  transform: translateX(50%) scale(0.75);
  transform-origin: bottom center;
  height: 50px;
  display: block;
  box-shadow: inset 10px 0px 0px 0px #F8AE15, 0px 0px 25px 0px rgba(0, 0, 0, 0.1); }
  .map__marker-inner.finished span:before {
    background-image: url("../images/marker-1.svg"); }
  .map__marker-inner.building span:before {
    background-image: url("../images/marker-2.svg"); }
  .map__marker-inner.planned span:before {
    background-image: url("../images/marker-3.svg"); }
  .map__marker-inner > span {
    font-weight: 700;
    font-size: 14px;
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding-left: 30px;
    white-space: nowrap; }
    .map__marker-inner > span:before {
      content: "";
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      width: 14px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .map__marker-inner:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #fff transparent transparent transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .map__marker-inner.map__marker-inner--flipped {
    top: 16px; }
  .map__marker-inner.map__marker-inner--flipped:after {
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent #fff transparent;
    top: -16px; }
  @media (min-width: 768px) {
    .map__marker-inner {
      transform: translateX(50%) scale(1); } }

.social {
  *zoom: 1;
  list-style: none;
  margin: 0 auto 45px;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem; }
  .social:before, .social:after {
    display: table;
    content: " "; }
  .social:after {
    clear: both; }

.social__item {
  padding: 0 6px 15px 6px;
  display: inline-block; }

.social__link {
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: block;
  background-color: #4F3A12;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  color: #fff; }
  .social__link:hover, .social__link:focus {
    background-color: #F8AE15;
    color: #fff; }

.card-container {
  margin-bottom: 75px; }
  .card-container > .h1,
  .card-container > .h2,
  .card-container > .h3,
  .card-container > .article-related__title,
  .card-container > .card__title {
    margin-bottom: 1.6em; }
  .card-container > .grid, .card-container > .grid--rev, .card-container > .grid--full, .card-container > .grid--g10, .card-container > .grid--g25 {
    margin-bottom: 45px; }

.card-container--spacing {
  margin-bottom: 135px; }

.card {
  border-left: 7px solid #F8AE15;
  height: 100%;
  margin-bottom: 25px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff; }

.card--story {
  background-color: #FAEBCC;
  background-image: url("../images/shape.png");
  background-repeat: no-repeat;
  background-size: 174px 162px;
  background-position: 100% 100%;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .card--story:hover, .card--story:focus {
    background-size: 180px 168px; }

.card--small {
  margin-top: 40px; }
  .card--small .card__body {
    height: auto;
    padding: 40px 30px; }
  @media (min-width: 768px) {
    .card--small {
      margin-top: 0; }
      .card--small .card__body {
        padding: 40px 80px; } }

.card__image {
  height: 125px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .card__image img {
    display: none; }
  .card__image + .card__body {
    height: 250px;
    padding: 30px 30px; }
  @media (min-width: 768px) {
    .card__image + .card__body {
      padding: 40px 40px; } }
  @media (min-width: 1024px) {
    .card__image + .card__body {
      padding: 40px 80px; } }

.card__body {
  height: 375px;
  padding: 45px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%; }
  .card__body .button {
    margin-top: 20px; }
  .card__body .js-ellipsis {
    max-height: 190px;
    width: 100%; }
  @media (min-width: 768px) {
    .card__body {
      padding: 75px 40px; }
      .card__body .js-ellipsis {
        max-height: 170px; } }
  @media (min-width: 1024px) {
    .card__body {
      padding: 75px 80px; } }

.card__title {
  margin-bottom: 10px; }

.card__date {
  font-weight: 300;
  margin-bottom: 20px; }

.card__description {
  margin-bottom: 0; }

.card__link:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 60px 60px 0;
  border-color: transparent #F8AE15 transparent transparent;
  top: 0;
  right: 0;
  opacity: 0;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  transform: scale(0);
  transform-origin: top right; }

.card__link i {
  position: absolute;
  top: 11px;
  right: 11px;
  color: #fff;
  z-index: 1;
  font-size: 12px;
  opacity: 0;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  transform: scale(0);
  transform-origin: top right; }

.card__link:hover:after,
.card__link:hover i, .card__link:focus:after,
.card__link:focus i {
  opacity: 1;
  transform: scale(1); }

.card__contact-details p {
  margin-bottom: 0; }

.grid__image,
.grid__video {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 25px; }
  .grid__image img,
  .grid__video img {
    display: none; }

.grid__video {
  position: relative; }
  .grid__video:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: #F8AE15;
    transform-origin: bottom left;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
    color: #fff;
    line-height: 40px;
    text-align: center; }
  .grid__video:hover:before, .grid__video:focus:before {
    transform: scale(1.5); }

@media (min-width: 768px) {
  .grid__item--floating {
    position: relative; }
    .grid__item--floating + .grid__item {
      float: right; }
    .grid__item--floating .grid__image,
    .grid__item--floating .grid__video {
      position: absolute;
      top: 0;
      left: 20px;
      right: 0;
      width: auto; } }

.text-block p:last-child {
  margin-bottom: 0; }

@media (min-width: 768px) {
  .text-block--left {
    padding-right: 60px; } }

@media (min-width: 768px) {
  .text-block--right {
    padding-left: 60px; } }

.text-block--spacing {
  margin-bottom: 55px; }

.pagination {
  text-align: right; }
  .pagination a:hover, .pagination a:focus {
    color: #F8AE15; }
  @media (min-width: 768px) {
    .pagination {
      margin-top: -30px; } }

.pagination__pager {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  position: relative;
  vertical-align: middle; }
  .pagination__pager.active {
    font-weight: 700; }
  .pagination__pager i {
    font-size: 7px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.accordion-item {
  background-color: #fff;
  border-left: 7px solid #F8AE15;
  margin-bottom: 35px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
  .accordion-item p {
    margin-bottom: 0; }

.accordion-trigger {
  cursor: pointer;
  padding: 24px 50px 23px 20px;
  position: relative;
  font-weight: 600; }
  .accordion-trigger:before, .accordion-trigger:after {
    position: absolute;
    top: 28px;
    right: 20px;
    line-height: 18px;
    display: block;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
    font-size: 13px; }
  .accordion-trigger:before {
    opacity: 1;
    visibility: visible;
    content: "\e90b"; }
  .accordion-trigger:after {
    opacity: 0;
    visibility: hidden;
    content: "\e909"; }
  .accordion-trigger.active:before {
    opacity: 0;
    visibility: hidden; }
  .accordion-trigger.active:after {
    opacity: 1;
    visibility: visible; }
  @media (min-width: 480px) {
    .accordion-trigger {
      padding: 29px 60px 28px 30px; }
      .accordion-trigger:before, .accordion-trigger:after {
        top: 34px;
        right: 30px; } }
  @media (min-width: 768px) {
    .accordion-trigger {
      padding: 29px 120px 28px 60px; }
      .accordion-trigger:before, .accordion-trigger:after {
        right: 40px; } }

.accordion-content {
  display: none;
  padding: 0 30px 35px 30px; }
  @media (min-width: 768px) {
    .accordion-content {
      padding: 0 120px 35px 60px; } }

/* =============================================================================
   PAGES
   ========================================================================== */
/* Home */
.hero {
  margin-bottom: 35px; }
  @media (min-width: 768px) {
    .hero {
      margin-bottom: 75px; } }

.hero__image {
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -150px; }
  @media (min-width: 768px) {
    .hero__image {
      height: 625px; } }

.hero__cards .grid, .hero__cards .grid--rev, .hero__cards .grid--full, .hero__cards .grid--g10, .hero__cards .grid--g25 {
  margin-bottom: 0; }

.hero__card {
  padding: 40px 25px 25px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
  border-top: 7px solid #F8AE15; }
  .hero__card .button {
    margin-top: 5px; }
  @media (min-width: 768px) {
    .hero__card {
      padding: 50px 35px 45px;
      margin-bottom: 0; } }

/* About */
.partners {
  padding: 50px 0 40px; }
  .partners .grid, .partners .grid--rev, .partners .grid--full, .partners .grid--g10, .partners .grid--g25 {
    margin-bottom: 0; }

.partner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 135px;
  opacity: 1;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  padding: 20px;
  position: relative; }
  .partner:hover, .partner:focus {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15); }
  .partner span:not(.partner__image) {
    display: block;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 400; }

.partner__image {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 170px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }
  .partner__image img {
    display: none; }

/* Locations */
.location-buttons {
  padding: 30px 0; }

.location-button {
  position: relative;
  padding: 15px 15px 6px 40px;
  display: inline-block;
  margin-bottom: 20px; }
  .location-button:before {
    content: "";
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 28px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  @media (min-width: 768px) {
    .location-button {
      margin-bottom: 3px; } }

.location-button--1:before {
  background-image: url("../images/marker-1.svg"); }

.location-button--2:before {
  background-image: url("../images/marker-2.svg"); }

.location-button--3:before {
  background-image: url("../images/marker-3.svg"); }

/* News */
.filter {
  display: inline-block;
  margin-top: 30px; }
  @media (min-width: 768px) {
    .filter {
      margin-top: 0; } }

.filter__button {
  margin: 0 10px 10px 0;
  vertical-align: top;
  float: left;
  clear: none; }

/* Mission */
.infographic {
  margin-top: 50px; }
  .infographic img {
    max-width: 200px; }

/* Contact */
.contact-form {
  margin-bottom: 40px; }

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm; }
  html {
    font-size: 10px; }
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit; }
  a,
  a:visited {
    text-decoration: underline; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  .page-footer,
  .main-nav {
    display: none; }
  .medium--one-half {
    width: 50%; }
  .medium--two-thirds {
    width: 66.66%; }
  .medium--one-third {
    width: 33.33%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-quarter {
    width: 25%; } }
