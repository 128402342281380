
/*============================================================================
  TYPOGRAPHY
==============================================================================*/

// Font Stacks

// Poppins
// light 300, regular 400, semi-bold 600, bold 700

@mixin default-font($size: 16, $weight: 400, $lineHeight: 1.5em) {
  font-family: 'Poppins', Helvetica, Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
  @include optimize-font;
}

@mixin display-font() {
  font-family: 'Poppins', Museo Sans, Helvetica, Arial, Verdana, sans-serif;
  font-weight: 700;
  @include optimize-font;
}


// Body

p {
  line-height: 1.8em;
  margin-bottom: 1.5em;
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: none;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
    text-decoration: none;
  }

  &:focus, &:active {
    outline: 0;
    text-decoration: none;
  }

  &.tdu {
    text-decoration: underline;
    //@include smart-underline($white, $black);
    &:hover, &:focus {
      text-decoration: underline;
      //@include smart-underline($white, $black);
    }
  }
}

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  color: $black;
  line-height: 1.2em;
  margin: 0 0 1.2em;

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 32px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 32px);
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 20px, 24px);
}

h4, .h4 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 16px, 20px);
}

h5, .h5 {  }

h6, .h6 {  }


// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: none;
  margin: 0;

  ul {
    list-style: none;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0; border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

.styled,
.rte ul {
  li {
    padding-left: 35px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 7px;
      height: 7px;
      background-color: $accent;
    }
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}


// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;
  border-left: 1px solid $black;
  font-weight: 700;
  @extend .icon-quote;
  position: relative;
  margin: 40px 0 40px 55px;
  padding-left: 25px;
  padding-right: 35px;
  max-width: 540px;
  @include fluid-type(font-size, $mediumSize, $largerSize, 16px, 20px);

  &:before {
    @extend .icon-font;
    color: $accent;
    @include fs(28);
    position: absolute;
    top: 0;
    left: -55px;
  }

  p {
    margin-bottom: 0;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    margin: 60px 0 60px 70px;

    &:before {
      left: -70px;
      @include fs(38);
    }
  }
}

.rte {
  a:not(.button) {
    @extend .tdu;
  }
}
