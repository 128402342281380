/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
@import "plugins/modaal";
@import "plugins/slick";
@import "plugins/select2";
@import "plugins/fancybox";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--large {
  max-width: 1670px;

  .container {
    padding: 0;

    @media #{$larger} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.container--full {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
}

.container--gray {
  background-color: $grayLight;
  padding-top: 45px;
  padding-bottom: 45px;

  p:last-child {
    margin-bottom: 0;
  }

  @media #{$medium} {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

.container--padding {
  padding-bottom: 305px;
  margin-bottom: -260px;

  @media #{$medium} {
    margin-bottom: -230px;
  }
}

.container--padding-2 {
  padding-bottom: 245px;
  margin-bottom: -200px;

  @media #{$medium} {
    margin-bottom: -170px;
  }
}

.container--margin {
  margin-bottom: 45px;

  @media #{$medium} {
    margin-bottom: 75px;
  }
}

.container--hero {
  min-height: 425px;
}

.container---with-slideshow {
  padding-bottom: 145px;
  margin-bottom: -100px;

  @media #{$medium} {
    padding-bottom: 175px;
  }
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 3px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 3px;
  }
  &:after {
    margin-top: 9px;
  }
}

@font-face {
  font-family: "toontjeshuis";
  src: url("../fonts/toontjeshuis.eot?kotmn0");
  src: url("../fonts/toontjeshuis.eot?kotmn0#iefix") format("embedded-opentype"),
    url("../fonts/toontjeshuis.ttf?kotmn0") format("truetype"),
    url("../fonts/toontjeshuis.woff?kotmn0") format("woff"),
    url("../fonts/toontjeshuis.svg?kotmn0#toontjeshuis") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"],
.icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "toontjeshuis" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-checkmark:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e904";
}
.icon-chevron-left:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e908";
}
.icon-minus:before {
  content: "\e909";
}
.icon-play:before {
  content: "\e90a";
}
.icon-plus:before {
  content: "\e90b";
}
.icon-quote:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}

// Exceptions: only add what you need!
.mb0.mb0 {
  margin-bottom: 0;
}
// .mb20.mb20 { margin-bottom: 20px; }
// .mb40.mb40 { margin-bottom: 40px; }

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  padding: 20px 0;
  height: 80px;
  background-color: $white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  transition: $transitionDefault;

  &.small {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
  }

  .container {
    position: static;
  }

  @media #{$medium} {
    height: 110px;
    padding: 35px 0;

    &.small {
      padding: 20px 0;
      height: 80px;

      .page-header__logo {
        margin-top: 0;

        img {
          width: 175px;
        }
      }

      .main-nav {
        margin: 12px 40px 0;

        .main-nav__aside {
          top: 0;
          transform: rotate(-90deg) translateY(-40px);

          a {
            width: 80px;
            @include fs(12);
            padding: 12px 0 15px;
            height: 40px;
          }
        }
      }
    }
  }

  @media #{$large} {
    height: 175px;
    padding: 40px 0;

    &.small {
      .main-nav {
      }
    }
  }

  @media #{$huge} {
    &.small {
      .main-nav {
        margin: 12px 0 0;
      }
    }
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  float: left;

  img {
    display: block;
    width: 175px;
    transition: $transitionDefault;
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }

  @media #{$large} {
    margin-top: 22px;

    img {
      width: 250px;
    }
  }

  @media #{$larger} {
    margin-top: 0;

    img {
      width: 385px;
    }
  }
}

.main-nav {
  @include optimize-font;
  color: $black;
  float: right;
  transition: $transitionDefault;

  a {
    color: $black;
    padding: 12px 15px;
    display: block;
    font-weight: 600;
    @include fs(17);
    @include fluid-type(font-size, $mediumSize, $largerSize, 14px, 17px);
  }

  a:hover,
  a:focus,
  li.active > a {
    color: $accent;
  }

  li.main-nav__aside.active a {
    color: $white;
    background: $accent;
  }

  @media #{$medium} {
    margin: 17px 40px 0;

    li {
      float: left;
      margin: 0 0 0 15px;
      position: relative;
    }

    a {
      border-top: 0;
      padding: 0;
    }

    li:hover a,
    li:focus a,
    a:hover,
    a:focus {
      + .sub-nav {
        visibility: visible;
        opacity: 1;
        transform: translateX(0) translateY(0);
      }
    }

    .main-nav__aside {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(-90deg) translateY(-40px);
      margin: 0;
      transform-origin: top right;
      transition: $transitionDefault;

      a {
        background-color: $black;
        color: $white;
        width: 110px;
        text-align: center;
        padding: 12px 0 15px;
        height: 40px;
        text-transform: uppercase;
        @include fs(12);
        font-weight: 700;
        transform: translateZ(0);

        &:hover,
        &:focus {
          background-color: $accent;
          color: $white;
        }
      }
    }
  }

  @media #{$large} {
    margin: 48px 40px 0;

    li {
      margin: 0 0 0 25px;
    }

    a {
      font-weight: 600;
    }

    .main-nav__aside {
      top: 35px;
      transform: rotate(-90deg) translateY(-50px);

      a {
        padding: 13px 0 23px;
        height: 50px;
        @include fs(14);
      }
    }
  }

  @media #{$huge} {
    margin: 48px 0 0;
  }
}

.main-nav__trigger {
  display: block;
  position: relative;
  padding: 12px 15px 12px 30px;
  background-color: transparent;
  color: $black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2px -15px 0 0;
  font-weight: 600;
  @include fs(17);
  line-height: 1;
  text-transform: none;

  &:after {
    display: none;
  }

  .icon-hamburger {
    position: absolute;
    right: 0;
    top: 12px;
    display: block;
    margin: 0;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    color: $accent;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;

  @media #{$medium} {
    margin: 0 0 0 -20px;
    display: block;
  }
}

.sub-nav {
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 12px;
  visibility: hidden;
  opacity: 0;
  transition: $transitionDefault;
  min-width: 100%;
}

.sub-nav__wrap {
  margin: 0 -25px;
  background-color: $white;
  padding-bottom: 10px;

  li {
    margin: 0;
    display: block;
    float: none;

    a {
      @include fs(14);
      padding: 8px 25px;
    }
  }
}

// Mobile menu
.modaal-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modaal-content-container {
  li {
    list-style: none;
    text-align: center;
  }

  a {
    color: $black;
    padding: 12px 15px;
    display: block;
    font-weight: 600;
    @include fs(20);
  }

  a:hover,
  a:focus,
  li.active a {
    color: $accent;
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 80px 0 20px;
  overflow: hidden;

  @media #{$medium} {
    padding: 110px 0 480px;
  }

  @media #{$large} {
    padding: 175px 0 480px;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  padding: 30px 0;
  background-color: $white;

  .grid {
    margin-bottom: 0;
  }

  @media #{$medium} {
    height: 480px;
    margin: -480px 0 0;
    z-index: 5;
    padding: 135px 0;
  }
}

.page-footer__list {
  @extend .unstyled;
  @include clearfix;
  text-align: center;

  li {
    margin-bottom: 5px;

    p {
      margin-bottom: 5px;
      line-height: 2em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media #{$medium} {
    li {
      padding-right: 15px;
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }
  }

  @media #{$large} {
    li {
      padding-right: 20px;
      float: left;
    }
  }

  @media #{$huge} {
    li {
      padding-right: 30px;
    }
  }
}

.page-footer__list--left {
  @media #{$medium} {
    text-align: left;
  }
}

.page-footer__list--right {
  @media #{$medium} {
    float: right;
    text-align: left;

    li {
      width: 50%;
      float: left;
    }
  }

  @media #{$large} {
    li {
      width: auto;
    }
  }
}

.page-footer__list--center {
  li {
    float: none;
    padding-right: 0;
  }
}

.page-footer__bottom {
  position: relative;
  height: 20px;
  margin-top: 30px;
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(-50%, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 0;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 16px;
    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {
  margin-top: 80px;
}

.article-detail__header {
  margin-bottom: 30px;
  padding-top: 20px;

  @media #{$medium} {
    h1 {
      margin-bottom: 0;
    }

    .grid__item.medium--one-sixth {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.article-detail__intro {
  font-weight: 600;
}

.article-detail__image {
  margin-bottom: 55px;
  text-align: center;
}

.article-related {
}

.article-related__title {
  @extend .h3;
  margin-bottom: 50px;
}

.page-section {
  margin-bottom: 70px;

  &:focus {
    outline: 0;
  }
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em !important;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow

.slideshow {
  position: relative;
  padding-bottom: 60px;

  @media #{$medium} {
    padding-bottom: 150px;
  }
}

.slideshow--rev {
  padding-bottom: 75px;

  .grid {
    .grid__item {
      &:first-child {
        float: right;
      }
    }
  }

  @media #{$medium} {
    .slideshow__body {
      margin-left: 0;
      margin-right: -35px;
    }
  }

  @media #{$large} {
    .slideshow__image {
      margin-right: 0;
      margin-left: -80px;
    }
  }
}

.slideshow__container {
  z-index: 1;
  line-height: 0;

  .slick-list {
    overflow: visible;
  }
}

.slideshow__current {
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 30px;
  width: 30px;

  @media #{$medium} {
    left: 30px;
    text-align: right;
  }

  @media #{$large} {
    left: 120px;
  }

  @media #{$massive} {
    left: 190px;
  }
}

.slideshow__navigation {
  z-index: 2;
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;

  @media #{$medium} {
    top: 0;
    left: auto;
    right: 0;
    width: 40%;
  }
}

.slideshow__prev,
.slideshow__next {
  background-color: transparent;
  color: $black;
  border: 1px solid $black;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-left: 5px;
  position: absolute;
  top: 0;

  &:after {
    color: $black;
    @include fs(10);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    line-height: 38px;
    height: 38px;
    display: block;
    transform: translate(0, 0);
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $accent;
    border: 1px solid $accent;

    &:after {
      color: $accent;
    }
  }
}

.slideshow__prev {
  right: 80px;

  &:after {
    content: "\e905";
  }

  &:hover,
  &:focus {
    &:after {
      transform: translate(-2px, 0);
    }
  }

  @media #{$medium} {
    right: 80px;
  }

  @media #{$huge} {
    right: 160px;
  }

  @media #{$xmassive} {
    right: 290px;
  }
}

.slideshow__next {
  right: 30px;

  &:after {
    content: "\e906";
  }

  &:hover,
  &:focus {
    &:after {
      transform: translate(2px, 0) scale(1);
    }
  }

  @media #{$medium} {
    right: 30px;
  }

  @media #{$huge} {
    right: 110px;
  }

  @media #{$xmassive} {
    right: 240px;
  }
}

.slideshow__image {
  height: 100%;
  min-height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  img {
    display: none;
  }

  @media #{$medium} {
    min-height: 550px;
  }

  @media #{$large} {
    margin-right: -80px;
  }
}

.slideshow__body {
  border-top: 7px solid $accent;
  padding: 30px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;

  .button {
    margin-top: 0;
    align-self: flex-start;
  }

  @media #{$medium} {
    margin-top: 100px;
    margin-left: -35px;
    min-height: 450px;
  }

  @media #{$large} {
  }

  @media #{$larger} {
    padding: 40px 60px;
  }

  @media #{$huge} {
    padding: 60px 100px;
  }

  @media #{$massive} {
    padding: 85px 150px;
  }
}

.slideshow__slide {
  .grid {
    margin-bottom: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  position: relative;
  margin: 0;

  @media #{$medium} {
    height: calc(100vh - 218px);
  }

  @media #{$large} {
    height: calc(100vh - 283px);
    max-height: calc(100vh - 283px);
  }

  button:after {
    content: none;
  }
}

.map__marker {
  position: absolute;
  width: 0;
  height: 0;
}

.map__marker-inner {
  position: absolute;
  background-color: $white;
  width: 150px;
  bottom: 16px;
  left: -150px;
  transform: translateX(50%) scale(0.75);
  transform-origin: bottom center;
  height: 50px;
  display: block;
  box-shadow: inset 10px 0px 0px 0px $accent,
    0px 0px 25px 0px rgba(0, 0, 0, 0.1);

  &.finished span:before {
    background-image: url("../images/marker-1.svg");
  }
  &.building span:before {
    background-image: url("../images/marker-2.svg");
  }
  &.planned span:before {
    background-image: url("../images/marker-3.svg");
  }

  > span {
    font-weight: 700;
    @include fs(14);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding-left: 30px;
    white-space: nowrap;

    &:before {
      content: "";
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      width: 14px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: $white transparent transparent transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.map__marker-inner--flipped {
    top: 16px;
  }

  &.map__marker-inner--flipped:after {
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent $white transparent;
    top: -16px;
  }

  @media #{$medium} {
    transform: translateX(50%) scale(1);
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 auto 45px;
  text-align: center;
  @include fs(14);
}

.social__item {
  padding: 0 6px 15px 6px;
  display: inline-block;
}

.social__link {
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: block;
  background-color: $black;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  color: $white;

  &:hover,
  &:focus {
    background-color: $accent;
    color: $white;
  }
}

// Cards

.card-container {
  margin-bottom: 75px;

  > .h1,
  > .h2,
  > .h3 {
    margin-bottom: 1.6em;
  }

  > .grid {
    margin-bottom: 45px;
  }
}

.card-container--spacing {
  margin-bottom: 135px;
}

.card {
  border-left: 7px solid $accent;
  height: 100%;
  margin-bottom: 25px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: $white;

  &:not(.card--no-link) {
    @extend .block-link;
  }

  &:hover,
  &:focus {
    .button {
      @extend .button:hover;
    }
  }
}

.card--story {
  background-color: $accentLight;
  background-image: url("../images/shape.png");
  background-repeat: no-repeat;
  background-size: 174px 162px;
  background-position: 100% 100%;
  transition: $transitionDefault;

  &:hover,
  &:focus {
    background-size: 180px 168px;
  }
}

.card--small {
  margin-top: 40px;

  .card__body {
    height: auto;
    padding: 40px 30px;
  }

  @media #{$medium} {
    margin-top: 0;

    .card__body {
      padding: 40px 80px;
    }
  }
}

.card__image {
  height: 125px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  img {
    display: none;
  }

  + .card__body {
    height: 250px;
    padding: 30px 30px;
  }

  @media #{$medium} {
    + .card__body {
      padding: 40px 40px;
    }
  }

  @media #{$large} {
    + .card__body {
      padding: 40px 80px;
    }
  }
}

.card__body {
  height: 375px;
  padding: 45px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  .button {
    margin-top: 20px;
  }

  .js-ellipsis {
    max-height: 190px;
    width: 100%;
  }

  @media #{$medium} {
    padding: 75px 40px;

    .js-ellipsis {
      max-height: 170px;
    }
  }

  @media #{$large} {
    padding: 75px 80px;
  }
}

.card__title {
  @extend .h3;
  margin-bottom: 10px;
}

.card__date {
  font-weight: 300;
  margin-bottom: 20px;
}

.card__description {
  margin-bottom: 0;
}

.card__link {
  @extend .block-link__target;

  &:after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 0 60px 60px 0;
    border-color: transparent $accent transparent transparent;
    top: 0;
    right: 0;
    opacity: 0;
    transition: $transitionDefault;
    transform: scale(0);
    transform-origin: top right;
  }

  i {
    position: absolute;
    top: 11px;
    right: 11px;
    color: $white;
    z-index: 1;
    font-size: 12px;
    opacity: 0;
    transition: $transitionDefault;
    transform: scale(0);
    transform-origin: top right;
  }

  &:hover,
  &:focus {
    &:after,
    i {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.card__contact-details {
  p {
    margin-bottom: 0;
  }
}

.grid__image,
.grid__video {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 25px;

  img {
    display: none;
  }
}

.grid__video {
  position: relative;
  @extend .icon-font;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: $accent;
    transform-origin: bottom left;
    transition: $transitionDefault;
    color: $white;
    @extend .icon-play;
    line-height: 40px;
    text-align: center;
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1.5);
    }
  }
}

.grid__item--floating {
  @media #{$medium} {
    position: relative;

    + .grid__item {
      float: right;
    }

    .grid__image,
    .grid__video {
      position: absolute;
      top: 0;
      left: 20px;
      right: 0;
      width: auto;
    }
  }
}

.text-block {
  p:last-child {
    margin-bottom: 0;
  }
}

.text-block--left {
  @media #{$medium} {
    padding-right: 60px;
  }
}

.text-block--right {
  @media #{$medium} {
    padding-left: 60px;
  }
}

.text-block--spacing {
  margin-bottom: 55px;
}

// Pagination
.pagination {
  text-align: right;

  a {
    &:hover,
    &:focus {
      color: $accent;
    }
  }

  @media #{$medium} {
    margin-top: -30px;
  }
}

.pagination__pager {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  position: relative;
  vertical-align: middle;

  &.active {
    font-weight: 700;
  }

  i {
    font-size: 7px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Accordion
.accordion-item {
  background-color: $white;
  border-left: 7px solid $accent;
  margin-bottom: 35px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  p {
    margin-bottom: 0;
  }
}

.accordion-trigger {
  cursor: pointer;
  padding: 24px 50px 23px 20px;
  position: relative;
  font-weight: 600;

  &:before,
  &:after {
    position: absolute;
    top: 28px;
    right: 20px;
    line-height: 18px;
    @extend .icon-font;
    display: block;
    transition: $transitionDefault;
    font-size: 13px;
  }

  &:before {
    opacity: 1;
    visibility: visible;
    content: "\e90b";
  }

  &:after {
    opacity: 0;
    visibility: hidden;
    content: "\e909";
  }

  &.active {
    &:before {
      opacity: 0;
      visibility: hidden;
    }
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  @media #{$small} {
    padding: 29px 60px 28px 30px;

    &:before,
    &:after {
      top: 34px;
      right: 30px;
    }
  }

  @media #{$medium} {
    padding: 29px 120px 28px 60px;

    &:before,
    &:after {
      right: 40px;
    }
  }
}

.accordion-content {
  display: none;
  padding: 0 30px 35px 30px;

  @media #{$medium} {
    padding: 0 120px 35px 60px;
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */

/* Home */
.hero {
  margin-bottom: 35px;

  @media #{$medium} {
    margin-bottom: 75px;
  }
}

.hero__image {
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -150px;

  @media #{$medium} {
    height: 625px;
  }
}

.hero__cards {
  .grid {
    margin-bottom: 0;
  }
}

.hero__card {
  padding: 40px 25px 25px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: $white;
  margin-bottom: 20px;
  border-top: 7px solid $accent;

  .button {
    margin-top: 5px;
  }

  @media #{$medium} {
    padding: 50px 35px 45px;
    margin-bottom: 0;
  }
}

/* About */
.partners {
  padding: 50px 0 40px;

  .grid {
    margin-bottom: 0;
  }
}

.partner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 135px;
  opacity: 1;
  transition: $transitionDefault;
  padding: 20px;
  position: relative;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  }

  span:not(.partner__image) {
    display: block;
    @include fs(20);
    font-weight: 400;
  }
}

.partner__image {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 170px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    display: none;
  }

  @media #{$large} {
    //right: 50px;
    //left: 50px;
  }
}

/* Locations */
.location-buttons {
  padding: 30px 0;
}

.location-button {
  position: relative;
  padding: 15px 15px 6px 40px;
  display: inline-block;
  margin-bottom: 20px;

  &:before {
    content: "";
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 28px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media #{$medium} {
    margin-bottom: 3px;
  }
}

.location-button--1 {
  &:before {
    background-image: url("../images/marker-1.svg");
  }
}

.location-button--2 {
  &:before {
    background-image: url("../images/marker-2.svg");
  }
}

.location-button--3 {
  &:before {
    background-image: url("../images/marker-3.svg");
  }
}

/* News */
.filter {
  display: inline-block;
  margin-top: 30px;

  @media #{$medium} {
    margin-top: 0;
  }
}

.filter__button {
  @extend .button--secondary;
  margin: 0 10px 10px 0;
  vertical-align: top;
  float: left;
  clear: none;
}

/* Mission */

.infographic {
  //text-align: center;
  margin-top: 50px;

  img {
    max-width: 200px;
  }
}

/* Contact */

.contact-form {
  margin-bottom: 40px;
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
